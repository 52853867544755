import { axiosBasicGet, axiosBasicPost, axiosGet, axiosPost, axiosRefreshGet } from "../axios"
import {
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAIL,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_FAIL,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAIL,
  POST_FEEDBACK_RESET
} from "../types/userConstant"
import qs from "qs"
import AsyncLocalStorage from "@createnextapp/async-local-storage"
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"
import { Mixpanel } from "../../utils/mixpanel"
import moment from "moment"

export const userLoginAction = (userName, password, extension) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })
    let type = loginTypeChecker(userName)
    let info = deviceInfo()
    const { data } = await axiosBasicPost(
      `/api/auth/authorize`,
      {
        userName,
        password,
        loginTypes: extension ? 'landline' : type,
        extension: extension ? extension : null,
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel,
      }
    )
    localStorage.setItem("token", JSON.stringify(data?.details?.token))
    localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
    localStorage.setItem("id", JSON.stringify(data?.details?.id))
    let res = null;
    if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
      await dispatch(getUserProfileAction(data?.details?.id, data?.details?.userType))
    }
  } catch (error) {
    //error?.response?.status === 401 && dispatch(prevUserLogoutAction())
    const errDetails = error?.response?.data?.details
    if (typeof errDetails === "object") {
      const keys = Object.keys(errDetails)
      if (keys.includes("count") && keys.includes("blockedTill") && keys.includes("attemptsLeft")) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: errDetails
        })
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: Object.values(errDetails)?.[0]
        })
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Something went wrong, Please try again!"
      })
    }
  }
}

export const googleLoginAction =
  ({ credential }) =>
  async dispatch => {
    try {
      dispatch({
        type: GOOGLE_LOGIN_REQUEST
      })
      let info = deviceInfo()
      const { data } = await axiosBasicPost(`/api/auth/authorize`, {
        userName: credential,
        loginTypes: "google",
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel
      })
      if (!["provider", "practitioner"].includes(data?.details?.userType?.toLowerCase())) {
        dispatch({
          type: GOOGLE_LOGIN_FAIL,
          payload: "Invalid Credentials"
        })
        return
      }
      localStorage.setItem("token", JSON.stringify(data?.details?.token))
      localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
      localStorage.setItem("id", JSON.stringify(data?.details?.id))

      if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
        // document.location.href = "/"
        await dispatch(getUserProfileAction(data?.details?.id, data?.details?.userType))
      }
    } catch (error) {
      dispatch({
        type: GOOGLE_LOGIN_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const clearUserLoginError = () => async dispatch => {
  dispatch({
    type: USER_LOGOUT
  })
}

export const validateLoginDetailsAction = (userName) => async dispatch => {
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: VALIDATE_LOGIN_DETAILS_REQUEST
    })

    const { data } = await axiosBasicPost(`/api/auth/validate`, { userName, loginTypes })
    
    if (!["provider", "practitioner"].includes(data?.details?.profileType?.toLowerCase())) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }

    dispatch({
      type: VALIDATE_LOGIN_DETAILS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    if (loginTypes === "email" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your mail"
      })
    } else if (loginTypes === "mobileNumber" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your number"
      })
    } else {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }

    return error.response
  }
}


export const passwordUpdateAction = (userName, otp, password) => async dispatch => {
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosBasicPost(
      `/api/auth/submitOTP`,
      { userName, loginTypes, otp, password }
    )

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}

//password reset from dashboard->security
export const passwordResetAction = (password, oldPassword) => async dispatch => {
  let info = deviceInfo()
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosPost(
      `/api/resetPassword`,
      {
        password,
        oldPassword,
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel,
      }
    )

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details?.password
    })
    return error
  }
}

export const UpdateProfileAction = profileImage => async dispatch => {
  try {
    dispatch({
      type: PROFILE_UPDATE_REQUEST
    })

    const { data } = await axiosPost(
      `/providers/v1/updateProfileImage`,
      { profileImage: profileImage },
      { headers: { "Content-Type": "application/json" } }
    )

    if (data.success) {
      dispatch(getUserProfileAction())
    }

    dispatch({
      type: PROFILE_UPDATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: PROFILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message
    })
    error?.response?.status === 401 && dispatch(userLogoutAction())
  }
}

// POST action for upload profile img
export const ImageUploadAction =
  ({ file, userId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: IMAGE_UPLOAD_REQUEST })
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", userId)
      formData.append("fileType", "profile")
      const { data } = await axiosPost(`/api/cs/upload/file`, formData)
      dispatch({
        type: IMAGE_UPLOAD_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: IMAGE_UPLOAD_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }


export const getUserProfileAction = (id, userType) => async dispatch => {
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    }
  }
  Mixpanel.identify(id)
  //different api will be used
  const { data } = await axiosGet(`/api/cs/${userType}/${id}/profile`, config)

  await AsyncLocalStorage.setItem("userInfo", JSON.stringify({ ...data?.details, userType }))
  // localStorage.setItem("profileImage", JSON.stringify(data.profileImage));
  Mixpanel.people.set({
    "ID": id,
    "Type": userType,
    "UserName": data?.details?.userName,
    "$name": `${data?.details?.firstName} ${data?.details?.middleName} ${data?.details?.lastName}`,
    "$email": data?.details?.email,
    "orgID": data?.details?.orgId
  })
  Mixpanel.register({
    "ID": id,
    "Type": userType,
    "UserName": data?.details?.userName,
    "Email": data?.details?.email,
    "orgID": data?.details?.orgId
  })
  dispatch({
    type: USER_LOGIN_SUCCESS,
    payload: { ...data?.details, userType }
  })
  // dispatch({ type: GET_ADMIN_SUCCESS, payload: data.profileImage });
  if (data?.details?.id !== null) {
    document.location.href = "/"
  }

  return data;
}


export const prevUserLogoutAction = () => async dispatch => {
  const res = await axiosGet(`/api/cs/config/logout`)
  if (res?.data?.message === 'Success') {
    // window.webkit.messageHandlers.logout.postMessage({ value: "harish" });
    localStorage.clear()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login";
  }
}

export const userLogoutAction = () => async dispatch => {
  try {

    const res = await axiosRefreshGet('/api/refresh')
    if (res?.data?.details?.token && res?.data?.details?.refreshToken && res?.data?.details?.id) {
      await AsyncLocalStorage.setItem("token", JSON.stringify(res?.data?.details?.token))
        .then(() => {
          AsyncLocalStorage.setItem("refreshToken", JSON.stringify(res?.data?.details?.refreshToken))
        }).then(() => {
          AsyncLocalStorage.setItem("id", JSON.stringify(res?.data?.details?.id))
        })
        .then(() => {
          window.location.reload()
        })
    }
  } catch (err) {
    localStorage.clear()
    //await handleLogout()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

// Submit Feedback: Dashboard -> Settings -> Feedback
export const postFeedbackAction =
  ({ userId, profileType, feedBackType, feedBack }) =>
  async dispatch => {
    try {
      dispatch({
        type: POST_FEEDBACK_REQUEST
      })
      await axiosPost(`/api/cs/config/feedback/${userId}/create`, {
        feedBackType,
        feedBack,
        profileType,
        userDate: moment().format("yyyy-MM-DDTHH:mm:ss.SSS")
      })

      const status = { isSuccess: true }

      dispatch({
        type: POST_FEEDBACK_SUCCESS,
        payload: status
      })

      return status

      // dispatch(userLogoutAction())
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_FEEDBACK_FAIL,
        payload: error?.response?.data?.details && Object.values(error?.response?.data?.details)
      })
      return error
    }
  }

export const resetPostFeedbackErrAction = () => async dispatch => {
  dispatch({
    type: POST_FEEDBACK_RESET
  })
}
